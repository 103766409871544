import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'

const pageTitle = "Bands"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <h3 className="pt-4 pb-4">
                            <a href="/en/pages/bands/">HK Amateur Radio Frequency Allocations 香港無線電頻道分配</a>
                        </h3>
                        <hr/>

                        <p className="text-muted">2015-07-09 </p>

                        <div className="panel panel-blue">
                            <div className="panel-heading">Amateur Radio 全球通訊資料?</div>
                            <div className="panel-body">
                                <p>除了可參考香港 OFCA 的資料外，作全球通常請參考美國 American Radio Relay League (ARRL) 之 <a
                                    href="http://www.arrl.org/frequency-allocations">Frequency
                                    Allocations</a> 及 <a
                                    href="http://www.arrl.org/files/file/Regulatory/Band%20Chart/Hambands_color.pdf">Band
                                    plans</a>。</p>
                            </div>
                        </div>

                        <table className="table table-bordered ">
                            <thead>
                            <tr>
                                <th>Frequency Bands</th>
                                <th>Wavelength</th>
                                <th>Frequency Allocation</th>
                                <th colSpan="2">Maximum Power (in dbW)</th>
                            </tr>

                            <tr>
                                <td>in MHz</td>
                                <td>λ</td>
                                <td>&nbsp;</td>
                                <td>carrier</td>
                                <td>PEP</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.8 - 2.0 ; 3.5 - 3.9</td>
                                <td>160 m ; 80 m</td>
                                <td>primary, amateur service</td>
                                <td>20</td>
                                <td>26</td>
                            </tr>
                            <tr>
                                <td>5.3515 - 5.3665</td>
                                <td>60 m</td>
                                <td>primary, amateur service</td>
                                <td>11.76</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>7.0 - 7.1</td>
                                <td>40 m</td>
                                <td>primary, amateur and amateur satellite service</td>
                                <td rowSpan="10">20</td>
                                <td rowSpan="10">26</td>
                            </tr>
                            <tr>
                                <td>7.1 - 7.2</td>

                                <td>primary, amateur service</td>
                            </tr>
                            <tr>
                                <td>10.1 - 10.15</td>
                                <td>30 m</td>
                                <td>secondary, amateur service</td>
                            </tr>
                            <tr>
                                <td>14.0 - 14.25</td>
                                <td rowSpan="2">20 m</td>
                                <td>primary, amateur and amateur satellite service</td>
                            </tr>
                            <tr>
                                <td>14.25 - 14.35</td>
                                <td>primary, amateur service</td>
                            </tr>
                            <tr>
                                <td>18.068 - 18.168</td>
                                <td>17 m</td>
                                <td rowSpan="4">primary, amateur and amateur satellite service</td>
                            </tr>
                            <tr>
                                <td>21.0 - 21.45</td>
                                <td>15 m</td>
                            </tr>
                            <tr>
                                <td>24.89 - 24.99</td>
                                <td>12 m</td>
                            </tr>
                            <tr>
                                <td>28.0 - 29.7</td>
                                <td>10 m</td>
                            </tr>
                            <tr>
                                <td>50.0 - 51.5<br/>52.025 - 52.11</td>
                                <td>6 m</td>
                                <td>primary, amateur service</td>
                            </tr>
                            <tr>
                                <td>144.0 - 146.0</td>
                                <td>2 m</td>
                                <td>primary, amateur and amateur satellite service</td>
                                <td>
                                    14<br/>(14 / 7 *)
                                </td>
                                <td>
                                    20
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    430.0 - 431.0<br/>
                                    435.0 - 438.0<br/>
                                    435.0 - 439.8
                                </td>
                                <td>70 cm</td>
                                <td>secondary, amateur service<br/>
                                    secondary, amateur satellite service<br/>
                                    secondary, amateur service
                                </td>
                                <td>
                                    20<br/>(14 / 7 *)
                                </td>
                                <td>
                                    26
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    2,400 - 2,450
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur satellite service
                                </td>
                                <td>
                                    20
                                </td>
                                <td>
                                    26
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    5,650 - 5,670
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur satellite service, <br/>limited to earth-to-space
                                    direction
                                </td>
                                <td>
                                    20
                                </td>
                                <td>
                                    26
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    5,725 - 5,850
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur service, <br/>users must accept interference from ISM
                                    users
                                </td>
                                <td>
                                    6#
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    10,450 - 10,500
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur and amateur satellite service
                                </td>
                                <td>
                                    7
                                </td>
                                <td>
                                    13
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    24,000 - 24,250
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur service, <br/>users must accept interference from ISM
                                    users
                                </td>
                                <td rowSpan="5">
                                    20
                                </td>
                                <td rowSpan="5">
                                    26
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    47,000 - 47,200
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    primary, amateur and amateur satellite service
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    76,000 - 77,500
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur and amateur satellite service
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    77,500 - 78,000
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    primary, amateur and amateur satellite service
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    78,000 - 81,000
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    secondary, amateur and amateur satellite service
                                </td>
                            </tr>
                            </tbody>

                        </table>


                        <p>* Information came from Hong Kong Office of the Communications Authority.
                        </p>


                        <p>* New 5.3515 - 5.3665 MHz band available as of March, 2017.</p>


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
